function init_design() {
  return unlayer.init({
    id: "editor-container",
    projectId: 1234,
    displayMode: "email",
  });
}

function load_design(design) {
  try {
    unlayer.loadDesign(design);
  } catch (err) {
  }
}

function set_appearance(theme) {
  unlayer.setAppearance({
    theme: theme,
  });
}

function export_html( callback) {
  unlayer.exportHtml(callback);
}
